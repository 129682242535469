import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hair Loss Prevention Stack",
  "path": "hair-loss-stack",
  "date": "2021-07-09"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I do not do anything special. I practice the "Big 3" (finasteride, minoxidil, ketoconazol shampoo) and some of the more fringe methods, like LLLT.`}</p>
    <p>{`There have been modifications (notably finasteride) and things have been added and dropped. I will have a more detailed breakdown later.
My YouTube channel outlines some of the changes in detail.`}</p>
    <p>{`As of July 12, 2021, since Oct/Nov 2020, I have been using or doing the following:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/products/minoxidil"
        }}>{`5% Minoxidil, foam`}</a>{`, once a day`}</li>
      <li parentName="ul">{`Nizoral, ketoconazol shampoo, 1-2 a week`}</li>
      <li parentName="ul">{`0.5mg finasteride every day (was 1mg daily and 1mg every other day; will add dates later)`}</li>
      <li parentName="ul">{`25 minutes of Low-Level Laser Therapy using the iRestore Professional`}</li>
      <li parentName="ul">{`Magnesium, Zinc, and Vitamin D3 supplement`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      